<template>
  <div>
    <nav class="navbar profile-navbar navbar-expand-lg px-0">
      <div class="w-100 px-2 profile-navbar-site">
        <div class="navbar-brand float-left">{{ title }}</div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto align-items-center">
            <!--          <li class="nav-item mx-0 dropdown">-->
            <!--            <el-dropdown trigger="click" class="nav-link dropdown-toggle special-view-button">-->
            <!--              <i class="special-view-icon"></i>-->
            <!--              <el-dropdown-menu slot="dropdown">-->
            <!--                <el-dropdown-item icon="el-icon-circle-check">Action 1</el-dropdown-item>-->
            <!--                <el-dropdown-item icon="el-icon-circle-check">Action 2</el-dropdown-item>-->
            <!--              </el-dropdown-menu>-->
            <!--            </el-dropdown>-->
            <!--          </li>-->
            <li class="nav-item mx-0 dropdown">
              <el-dropdown @command="switchLocale" trigger="click"
                           class="nav-link dropdown-toggle change-locale-button">
                <span class="el-dropdown-link">{{ $i18n.locale }}</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="kz">{{ $t('profile.settings.kz') }}</el-dropdown-item>
                  <el-dropdown-item command="ru">{{ $t('profile.settings.ru') }}</el-dropdown-item>
                  <!--                <el-dropdown-item command="en">Английский</el-dropdown-item>-->
                </el-dropdown-menu>
              </el-dropdown>
            </li>
            <!--          <li class="nav-item mx-0 dropdown ">-->
            <!--            <el-dropdown trigger="click" class="nav-link dropdown-toggle change-locale-button">-->
            <!--              <span class="el-dropdown-link"><i class="new-notifications"></i></span>-->
            <!--              <el-dropdown-menu slot="dropdown">-->
            <!--                <el-dropdown-item>Notification 1</el-dropdown-item>-->
            <!--                <el-dropdown-item>Notification 2</el-dropdown-item>-->
            <!--              </el-dropdown-menu>-->
            <!--            </el-dropdown>-->
            <!--          </li>-->
            <li class="nav-item exit-link">
              <a @click.prevent="logoutMethod" style="cursor: pointer!important;"
                 class="nav-link cursor-pointer">{{ $t('profile.logout') }}</a>
            </li>
            <li class="nav-item">
              <a href="/"><img src="images/qabilet-logo.svg" width="82"></a>
              <!--              <router-link to="/" tag="a"><img src="images/profile/logo.svg" width="44"></router-link>-->
            </li>
          </ul>
        </div>
      </div>

    </nav>

  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Vue from "vue";
import VueMask from "v-mask";

export default {
  props: ['title'],
  name: 'ProfileNavbar',
  data() {
    return {
      logged: false,
      collapsed: true,
      avatar: '',
      user: {},
    }
  },
  mounted() {
    if(!localStorage.getItem('user')){
      this.$http.get(window.API_ROOT + '/api/user/edit')
          .then((res) => {
            localStorage.setItem('user', JSON.stringify(res));
            this.$router.go()
          })
    }
    if(!localStorage.getItem('token')){
      this.$router.push('/')
    }
    Vue.use(VueMask);
    let res;
    res = JSON.parse(localStorage.getItem('user'))
    this.user = res.body.data;
    this.avatar = window.API_ROOT + '/api/user/avatar?file=' + res.body.data.avatar;

    this.$http.get(this.avatar)
        .then((res) => {
          var file = res.body;
          var reader = new FileReader();
          reader.readAsDataURL(file);
          this.avatar.src = reader.result;
        }).catch((e) => {

    });
    if (localStorage.getItem('role') != 'student') {
      this.$router.push('/profile-parent')
    }


    if (document.documentElement.clientWidth <= 992) {
      this.mobileActive = false
    }


  },

  methods: {
    switchLocale(lang) {
      localStorage.setItem('locale', lang);
      this.$i18n.locale = lang;
      this.$router.go(this.$router.currentRoute);
    },
    logoutMethod() {
      this.logout();
      localStorage.setItem('token', '');
      localStorage.setItem('user', '');
      window.location = '/';
    },
    clickaway() {
      this.collapsed = true
    },
    ...mapActions({
      'logout': 'logout'
    })
  },
}
</script>
<style>

.el-dropdown-link {
  text-transform: uppercase;
}

.profile-navbar-mobile {
  display: none;
}

.btn-back-menu {
  border: none;
  background-color: transparent;
}

.sideBarMobile {
  display: none;
}

@media screen and (max-width: 992px) {


  .menu-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    width: 45px;
    height: 45px;
    background: white;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 66px;
  }

  .nav-logo {
    width: 70px;
  }

  .profile-navbar-site {
    display: none;
  }

  .sideBarMobile {
    display: block;
    border-radius: 12px 0px 0px 12px;
  }

  .w3-container {
    position: absolute;
    right: -38px;
    top: 48px;
    margin-bottom: 10px;
    z-index: 1;
  }

  .modal-menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
  }

  .content .profile-navbar .nav-link {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;
    letter-spacing: 0.005em;
    font-feature-settings: 'tnum' on, 'lnum' on;

    color: #707C97;
    padding: 0.5rem 2rem;
  }

  .closeIcon {
    position: relative;
    right: 40px;
    top: 15px;
  }

}

@media screen and (max-width: 370px) {
  .menu-block {
    margin-left: 4px;
  }
}
</style>
